// 人员
function Person(opt = {}) {
    this.id = opt.id || '' // 唯一ID
    this.name = opt.name ||  '' // 名字
    this.avatar = opt.avatar ||  '' // 头像
    this.no = opt.no ||  '' // 序号/学号
    this.group = opt.group ||  {} // 所属班级/部门/分组
    this.gender = opt.gender ||  '' // 性别
    this.phone = opt.phone ||  '' // 电话
    this.email = opt.email ||  '' // 邮箱
    this.status = opt.status ||  1 // 状态, 1: 正常，2: 未到
    this.score = opt.score || 0 // 得分
    this.age = opt.age ||  0
    this.remark = opt.remark ||  '' // 备注
    return this
}

Person.prototype.getJSON = function () {
    return { ...this, group: this.group ? { ...this.group } : {} }
}

Person.prototype.toString = function () {
    return JSON.stringify(this.getJSON())
}

export default Person
