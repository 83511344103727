export function playSound(type) {
    let audio;
    switch(type) {
        case 'Done':
            audio = new Audio('/sounds/done.mp3');
            audio.play();
            break;
        case 'Error':
            audio = new Audio('/sounds/error.mp3');
            audio.play();
            break;
        case 'Absent':
            audio = new Audio('/sounds/absent.mp3');
            audio.play();
            break;
        case 'Present':
            audio = new Audio('/sounds/present.mp3');
            audio.play();
            break;
        case 'AllDone':
            audio = new Audio('/sounds/all-done.mp3');
            audio.play();
            break;
        default:
    }
}
