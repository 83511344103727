function Activity(opt = {}) {
    this.id = opt.id || '' // 唯一ID
    this.title = opt.title || '' // 标题
    this.createTime = opt.createTime || new Date() // 创建时间
    this.group = opt.group || {} // 分组
    this.completion = {} // 完成情况
}

Activity.prototype.getJSON = function () {
    return { ...this, group: this.group ? {...this.group} : {}}
}

Activity.prototype.toString = function () {
    return JSON.stringify(this.getJSON())
}

export default Activity
