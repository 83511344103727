import Activity from "./entity/Activity"
import Group from "./entity/Group"
import Person from "./entity/Person"
import User from "./entity/User"

function getTotal () {
    const match = window.location.search.match(/total=([^&]+)/);
    if (match) {
        return Number(match[1]);
    } else {
        return 40; // 默认40人
    }
}

export function load(date) {
    const activity = new Activity({
        id: 1,
        title: '作业完成情况',
        createTime: date || new Date()
    })
    const ziyu = new User({
        username: 'ziyu',
        nickname: '子鱼'
    })
    const persons = []
    const total = getTotal();
    for (let i = 0; i < total; i++) {
        let no = i + 1
        persons.push(new Person({
            id: no,
            name: 'P' + no,
            no
        }))
    }
    const group = new Group({
        id: 1,
        name: '三（2）班',
        subject: '数学',
        owner: ziyu,
        persons
    })
    
    activity.group = group
    return activity
}
