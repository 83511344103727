// 所属班级/部门/分组
function Group(opt = {}) {
    this.id = opt.id || '' // 唯一ID
    this.name = opt.name || '' // 名称
    this.owner = opt.owner || {} // 拥有者/班主任/管理者，[用户]
    this.subject = opt.subject || '' // 科目/分类，如：数学，语文、体育
    this.persons = opt.persons || [] // 人员集合
}

Group.prototype.getJSON = function () {
    return {
        ...this,
        owner: this.owner ? {...this.owner} : {},
        persons: this.persons ? this.persons.map(person => ({...person})) : []
    }
}

Group.prototype.toString = function () {
    return JSON.stringify(this.getJSON())
}

export default Group
