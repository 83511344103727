// 系统用户
function User(opt = {}) {
    this.username = opt.username || '' // 唯一ID
    this.nickname = opt.nickname || '' // 昵称
    this.password = opt.password || '' // 登录密码
    this.phone = opt.phone || '' // 手机号
    this.createTime = opt.createTime || '' // 创建时间
    this.status = opt.status || 1 // 状态，1: 启用，0: 禁用
    return this
}

User.prototype.getJSON = function () {
    return { ...this }
}

User.prototype.toString = function () {
    return JSON.stringify(this.getJSON())
}
export default User
