import moment from 'moment';
import React, { useState, useEffect } from 'react';
import './App.css';
import { load } from './DataInit';
import { playSound } from './Utils'

function App() {
  const todayActivity = load();
  const [activity] = useState(todayActivity);
  const [editMode, setEditMode] = useState(false);
  const [persons, setPersons] = useState(activity.group.persons);

  useEffect(() => {
    setPersons(activity.group.persons);
  }, [activity]);

  let keyPressed = false;
  let lastKeyPressTime = 0;

  document.onkeydown = function(event) {
    if (event.key === 'e') {
      const currentTime = new Date().getTime();
      if (keyPressed && currentTime - lastKeyPressTime < 300) {
        // 连续敲击两次字母 "e" 
        setEditMode(true)
      }
      keyPressed = true;
      lastKeyPressTime = currentTime;
    }
    console.log(event.key)
    if (event.key === 'Escape') {
      setEditMode(false)
    }
  };

  document.onkeyup = function(event) {
    if (event.key !== 'e') {
      keyPressed = false;
    }
  };

  const handleBtnClick = (index) => {
    const newPersons = [...persons];
    const person = newPersons[index];
    let soundType = '';

    if (editMode) {
      person.status = person.status === 2 ? 1 : 2
      soundType = person.status === 2 ? 'Absent' : 'Present'
    } else {
      if (person.status === 2) {
        return false;
      }
      person.score = person.score === 0 ? 100 : 0;
      if (person.score === 0) {
        soundType = 'Error';
      } else {
        let hasError = false;
        newPersons.forEach(p => {
          if (p.status === 1 && p.score === 0) {
            hasError = true;
          }
        })
        if (hasError) { // 班级里还有其他学生未完成
          soundType = 'Done';
        } else {
          soundType = 'AllDone';
        }
      }
    }
    playSound(soundType);
    setPersons(newPersons);
  }

  const handleTitleDbCLick = () => {
    document.body.requestFullscreen();
  }

  const getContainerClsName = () => {
    const mod = persons.length % 8;
    let clsName = (mod > 0 && mod < 8) ? 'Persons Alone' : 'Persons'
    if (editMode) {
      clsName += ' Edit'
    }
    return clsName
  }

  const getBtnClsName = (person) => {
    let className = 'CoolButton';
    if (person.score !== 0) {
      className += ' Done';
    }
    if (person.status === 2) {
      className += ' Disabled';
    }
    return className;
  }
  return (
    <div className="App">
      <div className="Title" onDoubleClick={handleTitleDbCLick}>
        {moment(activity.createTime).format('MM/DD')} {activity.title}
      </div>
      {/* <div className="SubTitle">
        <span>{activity.group.name}</span>
        <span>{activity.group.subject}</span>
        <span>{moment(activity.createTime).format('YYYY-MM-DD')}</span>
      </div> */}
      
      <div className={getContainerClsName()}>
        {persons.map((person, index) => {
          return (
            <button
              className={getBtnClsName(person)}
              key={index}
              onClick={() => handleBtnClick(index)}
            >
              <span>{person.no}</span>
            </button>
          )
        })}
      </div>
    </div>
  );
}

export default App;
